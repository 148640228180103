import {SID_GAME, SID_LOADING, SID_TRANSITION, SID_WELCOME_1, SID_WELCOME_2, SID_WELCOME_3} from "../../compositions/scenesTypes";

const initialState = {
  current: [SID_LOADING],
  isPaused: true,
  [SID_LOADING]: {
    timeline: 0,
    fullTime: 600,
  },
  [SID_WELCOME_1]: {
    timeline: 0,
    fullTime: 1000,
  },
  [SID_WELCOME_2]: {
    timeline: 0,
    fullTime: 400,
  },
  [SID_WELCOME_3]: {
    timeline: 0,
    fullTime: 3200,
  },
  [SID_TRANSITION]: {
    timeline: 0,
    fullTime: 600,
  },
  [SID_GAME]: {
    timeline: 0,
    fullTime: 1000,
  }
}

export default function scenes(state = initialState, action) {
  switch (action.type) {
    case 'SET_TIMELINE': {
      const { sid ,timeline } = action.payload;
      const sceneParams = {...state[sid], timeline}
      return {
        ...state,
        [sid]: sceneParams
      };
    }
    case 'SET_SCENE': {
      const { sid } = action.payload;
      const scene = {...state[sid], timeline: 0}
      return {
        ...state,
        current: [sid],
        [sid]: {...scene}
      };
    }
    default:
      return state
  }
}