import {CLOSE_POPUP, OPEN_POPUP} from "../actionTypes";

const initialState = {
  isPopup: false,
  compList: null
}

export default function popup(state = initialState, action) {
  switch (action.type) {
    case OPEN_POPUP: {
      const {isPopup, compList} = action.payload;
      return {
        ...state,
        isPopup,
        compList
      };
    }
    case CLOSE_POPUP: {
      const {isPopup} = action.payload;
      return {
        ...state,
        isPopup
      };
    }

    default:
      return state
  }
}